.organization-type-icon{
    display: inline-block;
    padding: 2px;
    width: 15px;
    /*height: 15px;*/
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    margin: 0 1px 0 1px;
    font-style: normal;
    color: #fff;
    vertical-align: center;
    background: rgb(204,204,204);
}
.organization-name-in-row{
    font-size: 16px;
    word-break: break-all;
    color: #000;
    margin: 12px 0 0 12px;
    text-decoration-color: black;
    text-decoration: underline;
}
.organization-name-not-underlined{
  font-size: 16px;
  color: #000;
  margin: 12px 0 0 12px;
  text-decoration-color: black;
}
.organization-offers-in-row-icon{
    display: inline-block;
    padding: 2px;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    margin: auto 1px auto 1px;
    font-style: normal;
    color: #fff;
    vertical-align: center;
    background: rgb(204,204,204);
}
.organization-offers-in-row{
    vertical-align: center;
}
.locations-departments-in-row{
    display: inline-block;
    color: rgb(152, 152, 152);
    margin: 12px 12px 12px 12px;
}
.position-name-in-row{
    display: inline-block;
    color: rgb(152, 152, 152);
    margin: 12px 12px 12px 12px;
}
.organization-type-container-in-row{
    word-break: break-all;
    display: inline-block;
    vertical-align: middle;
}
.organization-name-container-in-row{
    display: inline-block;
}
.organization-type-icon-fluid{
    width: auto;
    padding: 6px;
    font-size: 12px;
    vertical-align: middle;
    display: inline-block;
    /*height: 15px;*/
    text-align: center;
    border-radius: 4px;
    margin: 0 1px 0 1px;
    font-style: normal;
    color: #fff;
    background: rgb(204,204,204);
}
.t-icon-active{
    background: rgb(25,118,210);
}
.m-icon-active{
    background: rgb(25,118,210);
}
.c-icon-active{
    background: rgb(25,118,210);
}
