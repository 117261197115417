.Organization-profile-container {
  justify-content: center;
  width: 100%;
}

.Organization-profile-title {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding-left: 10px;
  align-items: center;
}
