.AppToolbar-main-content {
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
table .table-cell-phone {
    white-space: nowrap;
}

.table-wrapper {
    overflow-x: auto;
}
.breadcrumbs__crumb {
    text-decoration: none;
}
.breadcrumbs__separator {
}

.Login {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.Login-form {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

  .Login-form-content {
    align-items: center;
    justify-items: center;
    height: 150px;
  }

  .Login-form-buttons {
    display: flex;
    align-content: center;
    justify-content: center;
  }

html, body {
  height: 100%;
  background-color: #fafafa;
}

.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40px;
  margin-left: 20px;
}

.wordWrapBreakAll {
  word-break: break-all;
}

.preBreak {
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-dialog-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-error {
  display: flex;
  align-items: center;
  justify-content: center;
}

  .App-error-img {
    margin-right: 7px;
    height: 20px;
  }

.App-not-found {
  margin: auto;
  text-align: center;
}

  .App-not-found-img {
   margin-top: 5%;
   margin-bottom: 2%;
   height: 60px;
  }

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Register-email-form {
  margin: auto;
  width: 50%;
}

.Register-email-sent {
  margin: auto;
  text-align: center;
}

  .Register-tick-img {
    margin-top: 5%;
    margin-bottom: 2%;
    height: 60px;
  }

.Register-activate-form {
  margin: auto;
  width: 40%;
}
.staff-tag-with-organization-in-row {
  display: flex;
  vertical-align: center;
}

.staff-toolbar-icons-row {
  display: flex;
  vertical-align: center;
}

.staff-organization-name {
  margin-left: 4px;
}

.staff-name-container-in-row{
  display: inline-block;
  vertical-align: middle;
}

.staff-name-underlined{
  word-break: break-all;
  color: #000;
  margin: 0px 0 0 0px;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  text-decoration: underline;
}

.staff-name-not-underlined{
  color: #000;
  margin: 0px 0 0 0px;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

.staff-organization-name-underlined{
  color: #000;
  margin: 0px 0 0 0px;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  text-decoration: underline;
}

.staff-position-name-in-row{
  color: #000;
  margin: 0px 0 0 0px;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

.staff-container-row{
  display: flex;
  width: 100%;
  vertical-align: center;
}

.staff-organization-name-container{
  width: 100%;
  vertical-align: center;
}

.staff-position-container{
  width: 100%;
  vertical-align: center;
}

.staff-position-name-secondary{
  color: #cccccc;
  margin: 0px 0px 0px 12px;
  -webkit-text-decoration-color: gray;
          text-decoration-color: gray;
}

.staff-roles-group {
  margin: 20px 10px 20px 10px;
}

.staff-expansion-panel{
  background-color: transparent;
}

._offers-list_disabled {
    background: #eeeeee;
}

._offers-list_disabled td {
    color: rgba(0, 0, 0, 0.26);
}
.User-profile-container {
  display: flex;
  justify-content: center;
}

  .User-info-container {
    width: 100%;
  }

  .User-info-title {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding-left: 10px;
    align-items: center;
  }

  .Content-title {
    padding: 12px;
  }

  .User-info-form {
    /*padding: 20px;*/
    margin-bottom: 10px;
  }

.organization-type-icon{
    display: inline-block;
    padding: 2px;
    width: 15px;
    /*height: 15px;*/
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    margin: 0 1px 0 1px;
    font-style: normal;
    color: #fff;
    vertical-align: center;
    background: rgb(204,204,204);
}
.organization-name-in-row{
    font-size: 16px;
    word-break: break-all;
    color: #000;
    margin: 12px 0 0 12px;
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
    text-decoration: underline;
}
.organization-name-not-underlined{
  font-size: 16px;
  color: #000;
  margin: 12px 0 0 12px;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}
.organization-offers-in-row-icon{
    display: inline-block;
    padding: 2px;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    margin: auto 1px auto 1px;
    font-style: normal;
    color: #fff;
    vertical-align: center;
    background: rgb(204,204,204);
}
.organization-offers-in-row{
    vertical-align: center;
}
.locations-departments-in-row{
    display: inline-block;
    color: rgb(152, 152, 152);
    margin: 12px 12px 12px 12px;
}
.position-name-in-row{
    display: inline-block;
    color: rgb(152, 152, 152);
    margin: 12px 12px 12px 12px;
}
.organization-type-container-in-row{
    word-break: break-all;
    display: inline-block;
    vertical-align: middle;
}
.organization-name-container-in-row{
    display: inline-block;
}
.organization-type-icon-fluid{
    width: auto;
    padding: 6px;
    font-size: 12px;
    vertical-align: middle;
    display: inline-block;
    /*height: 15px;*/
    text-align: center;
    border-radius: 4px;
    margin: 0 1px 0 1px;
    font-style: normal;
    color: #fff;
    background: rgb(204,204,204);
}
.t-icon-active{
    background: rgb(25,118,210);
}
.m-icon-active{
    background: rgb(25,118,210);
}
.c-icon-active{
    background: rgb(25,118,210);
}

.Organization-profile-container {
  justify-content: center;
  width: 100%;
}

.Organization-profile-title {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding-left: 10px;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

