html, body {
  height: 100%;
  background-color: #fafafa;
}

.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40px;
  margin-left: 20px;
}

.wordWrapBreakAll {
  word-break: break-all;
}

.preBreak {
  white-space: pre-wrap;
  word-break: break-word;
  hyphens: auto;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-dialog-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-error {
  display: flex;
  align-items: center;
  justify-content: center;
}

  .App-error-img {
    margin-right: 7px;
    height: 20px;
  }

.App-not-found {
  margin: auto;
  text-align: center;
}

  .App-not-found-img {
   margin-top: 5%;
   margin-bottom: 2%;
   height: 60px;
  }

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
