.staff-tag-with-organization-in-row {
  display: flex;
  vertical-align: center;
}

.staff-toolbar-icons-row {
  display: flex;
  vertical-align: center;
}

.staff-organization-name {
  margin-left: 4px;
}

.staff-name-container-in-row{
  display: inline-block;
  vertical-align: middle;
}

.staff-name-underlined{
  word-break: break-all;
  color: #000;
  margin: 0px 0 0 0px;
  text-decoration-color: black;
  text-decoration: underline;
}

.staff-name-not-underlined{
  color: #000;
  margin: 0px 0 0 0px;
  text-decoration-color: black;
}

.staff-organization-name-underlined{
  color: #000;
  margin: 0px 0 0 0px;
  text-decoration-color: black;
  text-decoration: underline;
}

.staff-position-name-in-row{
  color: #000;
  margin: 0px 0 0 0px;
  text-decoration-color: black;
}

.staff-container-row{
  display: flex;
  width: 100%;
  vertical-align: center;
}

.staff-organization-name-container{
  width: 100%;
  vertical-align: center;
}

.staff-position-container{
  width: 100%;
  vertical-align: center;
}

.staff-position-name-secondary{
  color: #cccccc;
  margin: 0px 0px 0px 12px;
  text-decoration-color: gray;
}

.staff-roles-group {
  margin: 20px 10px 20px 10px;
}

.staff-expansion-panel{
  background-color: transparent;
}
