.Register-email-form {
  margin: auto;
  width: 50%;
}

.Register-email-sent {
  margin: auto;
  text-align: center;
}

  .Register-tick-img {
    margin-top: 5%;
    margin-bottom: 2%;
    height: 60px;
  }

.Register-activate-form {
  margin: auto;
  width: 40%;
}