.Login {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.Login-form {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

  .Login-form-content {
    align-items: center;
    justify-items: center;
    height: 150px;
  }

  .Login-form-buttons {
    display: flex;
    align-content: center;
    justify-content: center;
  }
