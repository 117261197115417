.User-profile-container {
  display: flex;
  justify-content: center;
}

  .User-info-container {
    width: 100%;
  }

  .User-info-title {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding-left: 10px;
    align-items: center;
  }

  .Content-title {
    padding: 12px;
  }

  .User-info-form {
    /*padding: 20px;*/
    margin-bottom: 10px;
  }
